/* container styling */
.dM {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}

.dM .Kq {
    display: none;
}

.dM .jb {
    /* display: none; */
    color: #FFF;
    font-family: "Sofia Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.dM .DAY_ITEM {
    background: none;
}

.dM .or.FC ._1g {
    background: #6000E9;
    border-color: #6000E9;
}


/* hide month label */
.dM .jb {
    display: none;
}

/* fix weekend color */
._2E {
    color: white !important;
}