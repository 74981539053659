/* ---- remove backgrounds ---- */

/* main calendar background. keep aub components of the card background.*/
.calendar-daily-container .rbc-calendar *:not(.calendar-event-card-container):not(.calendar-event-card-container *):not(.event-card-blocked) {
    background: transparent;
}



/* ---- get rid of borders ---- */

/* header with time labels */
.calendar-daily-container .rbc-time-header * {
    border: none;
    border-left: none !important;
    border-right: none !important;
}

.calendar-daily-container .rbc-time-header.rbc-overflowing {
    border-right: none !important;
}

/* the time view container */
.calendar-daily-container .rbc-time-view {
    border: none;
    margin-top: 15px;
}

.calendar-daily-container .rbc-time-content {
    border-top: none;
    padding-top: 5px;
}

/* custom class for date labels */
.calendar-daily-container .date-label-number {
    border: 1px solid #D7C0FB !important;
}

/* each time slot cell */
.calendar-daily-container .rbc-time-slot {
    border: none !important;
}

/* left side time label borders */
.calendar-daily-container .rbc-time-gutter .rbc-timeslot-group {
    border: none !important;
}

/* first time slot column left borders */
.calendar-daily-container .rbc-day-slot:nth-child(2) *:not(.calendar-card-left-border) {
    border-left: none;
}

/* time slot columns bottom borders */
.rbc-timeslot-group:nth-last-child(2),
.calendar-daily-container .rbc-today .rbc-timeslot-group:nth-last-child(3) {
    border-bottom: none;
}

/* set border colors */
.calendar-daily-container .rbc-calendar *:not(.calendar-card-left-border) {
    border-color: rgba(255, 255, 255, 0.20);
}

/* set border to top most row */
.calendar-daily-container .rbc-timeslot-group:first-child {
    border-top-width: 1px;
}






/* ---- left side time label ---- */

/* set width of time label column */
.calendar-daily-container .rbc-time-gutter {
    /*fix row height after commented */
    /* display: inline-block; */
    width: 50px;
}

/* shift label to align with time slot borders */
.calendar-daily-container .rbc-time-gutter .rbc-timeslot-group {
    position: relative;
    overflow: visible;
    font-family: 'Sofia Pro Regular';
}

.calendar-daily-container .rbc-time-gutter .rbc-time-slot .rbc-label {
    /* background: white !important;  */
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}






/* ---- time indicator ---- */
.calendar-daily-container .rbc-current-time-indicator {
    border: 1px solid white !important;
}



/* ---- header with time labels ---- */

/* whole header container */
.calendar-daily-container .rbc-time-header-content {
    margin-bottom: 22px;
    height: auto;
}

/* header part with time labels */
.calendar-daily-container .rbc-time-header-cell {
    /* background:white; */
    padding: 0px;
}

/* all day event container*/
.calendar-daily-container .rbc-allday-cell {
    display: none;
}

/* today label */
.calendar-daily-container .rbc-today .date-label-number {
    background: #6000E9;
    border: 0 !important
}








/* event styling */

.calendar-daily-container .rbc-event {
    border-radius: 0px !important;
    background-color: transparent !important;
    padding: 0px !important;
    border: none !important;
}

.calendar-daily-container .rbc-background-event {
    /* background-color: #3b0606 !important; */
    border: none;
    padding: 0px !important;
}

.calendar-daily-container .rbc-events-container {
    padding: 0px;
    background: transparent;
    width: 100%;
}

.calendar-daily-container .rbc-event-label {
    display: none;
}

.calendar-daily-container .rbc-time-slot {
    /* background: #eaf3fa; */
    color: #74a4c3;

}

.calendar-daily-container .rbc-event.rbc-selected{
    outline: none   !important;
}





/* hide time header in day view */
.calendar-daily-container .rbc-time-header {
    display: none;
}



/* hide time selector label */
.calendar-daily-container .rbc-slot-selection{
    display: none;
}


/* blocked card  background */
.calendar-daily-container .event-card-blocked:not(p) {
    background: repeating-linear-gradient(
        -45deg, /* Angle of the lines */
        rgba(211, 211, 211, 0.1), /* Semi-transparent light gray for the lines */
        rgba(211, 211, 211, 0.2) 1px, /* Thickness of the line */
        transparent 2px, /* Space after the line */
        transparent 10px /* Total space before the next line */
    );
}